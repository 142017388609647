<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="modal fade my_saved_horses_detail_modal">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <Detail :item="show_detail" :type="'horse'" v-if="show_detail !== null"></Detail>
        </div>
      </div>
    </div>
    <div class="container">
      <h4>My Saved Horses</h4>
      <div class="d-flex justify-content-center align-items-center loading" v-if="loading"><a-spin /></div>
      <div class="d-flex justify-content-start flex-wrap listing-cards-container" v-if="loading === false" >
        <div class="d-flex justify-content-start listing-card" v-for="item in items" :key="item.id">
          <div class="cover">
            <div style="position: absolute; right: 0; top: 0;">
              <a href="javascript:;" style="padding: 5px; border-bottom-left-radius: 5px; background: #fff;" @click="toggleFavorite(item);">
                <HeartOutlined v-if="isFavorited(item) === false" />
                <HeartFilled v-if="isFavorited(item)" />
              </a>
            </div>
            <img class="cover-image placeholder-image" @click.self="openDetails(item)" src="../assets/img/placeholder_logo.png" v-if="item.images.length === 0" />
            <img class="cover-image" @click.self="openDetails(item)" :src="item.images[0]" v-if="item.images.length > 0" />
          </div>
          <div class="details" @click="openDetails(item)">
            <div class="price" v-if="item.price_must_contact === false" style="margin-bottom: 5px; font-size: 15px; font-weight: bold;">{{formatPrice(item.price)}}</div>
            <div class="price" v-if="item.price_must_contact" style="margin-bottom: 5px; font-size: 15px; font-weight: bold;">Contact seller for price</div>
            <div class="name"><strong>Name: </strong> {{item.horse_name}}</div>
            <div class="breed" v-if="item.breed.length > 0"><strong>Breed: </strong>{{item.breed[0]}}</div>
            <div class="discipline" v-if="item.discipline.length > 0"><strong>Discipline: </strong>{{item.discipline[0]}}</div>
            <div class="age"><strong>Age: </strong>{{item.age}} {{item.age_unit === 'MONTH' ? 'months' : 'years'}} old</div>
            <div class="location" v-if="item.address"><strong>Location: </strong>{{item.address.state}}</div>
          </div>
        </div>
        <div v-if="items.length === 0">
          No result found!
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import axios from 'axios';
import { EditOutlined } from '@ant-design/icons-vue';
import ACard from 'ant-design-vue/lib/card';
import ASpin from 'ant-design-vue/lib/spin';
import 'ant-design-vue/lib/card/style/index.css';
import 'ant-design-vue/lib/spin/style/index.css';
import Detail from '@/components/Detail.vue';
import { HeartOutlined, HeartFilled } from '@ant-design/icons-vue';
import $ from "jquery";

import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';

export default {
  components: {
    BaseLayout,
    SubHeader,
    ACard,
    ASpin,
    HeartOutlined,
    HeartFilled,
    Detail,
    'a-card-meta': ACard.Meta,
    'edit-outlined': EditOutlined
  },
  data() {
    return {
      loading: false,
      items: [],
      selected_item: null,
      show_detail: null,
      formatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }),
    }
  },
  async mounted() {
    let $this = this;
    document.title = 'My Saved Horses - Mane Street Market';
    window.scrollTo(0, 0);
    $('.my_saved_horses_detail_modal').on('shown.bs.modal', function (e) {
      $this.show_detail = $this.selected_item;
    });
    $('.my_saved_horses_detail_modal').on('hidden.bs.modal', function (e) {
      $this.show_detail = null;
    });
    await this.loadItems();
  },
  methods: {
    async loadItems() {
      this.loading = true;
      try {
        let response = await axios.get('/api/my-saved-horses');
        this.items = response.data.items;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    openDetails(item) {
      this.selected_item = item;
      $('.my_saved_horses_detail_modal').modal('show');
    },
    formatPrice(value) {
      return this.formatter.format(value);
    },
    async toggleFavorite(item) {
      if (this.$store.state.user == null) {
        $('#loginModal').modal('show');
        $('#myTab a[href="#sign-in-content"]').tab('show')
        return;
      }
      await this.$store.dispatch('toggleFavoriteHorse', item.id);
      this.loading = true;
      setTimeout(async () => {
        await this.loadItems();
      }, 1000)
    },
    isFavorited(item) {
      return this.$store.state.favorite_horses.indexOf(item.id) > -1;
    }
  }
}
</script>

<style lang="scss" scoped>
  .listing-cards-container {
    gap: 10px;
    margin-top: 24px;
  }

  .listing-card {
    width: calc(33% - 10px * 2 / 3);
    font-size: 12px;
    border: 1px solid #ddd;
    cursor: pointer;

    .cover {
      width: 120px;
      height: 120px;
      margin-right: 10px;
      position: relative;

      .cover-image {
        object-fit: cover;
        width: 120px;
        height: 120px;
      }

      .cover-image.placeholder-image {
        object-fit: contain;
        border: none;
        padding: 8px;
      }
    }

    .details {
      padding: 8px;
    }
  }

  @media screen and (max-width: 480px) {
    .listing-card {
      width: calc(50% - 10px / 2);
      flex-direction: column;

      .cover {
        width: 100%;
        height: 150px;

        .cover-image {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
</style>
